import React from "react"
import styled from "styled-components"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {Page} from "@indebted/components/Page"
import {PaymentProcessingImage} from "@indebted/assets"
import {Props, Prop, PropValue, PropLabel} from "@indebted/components/Props"
import {TextToHTML} from "@indebted/components/TextToHTML"

function PaymentBeingProcessing({i18n, ClientName, PaymentAmount}) {
	return (
		<Page>
			<Grid>
				<Typography align="center" variant="h6">
					{i18n.Title}
				</Typography>
			</Grid>

			<Grid>
				<TextToHTML align="center" text={i18n.Message} />
			</Grid>

			<GridImage>
				<PaymentProcessingImage />
			</GridImage>

			<Props>
				<Prop>
					<PropLabel align="right">{i18n.PaymentAmountLabel}</PropLabel>
					<PropValue align="left">{PaymentAmount}</PropValue>
				</Prop>
				<Prop>
					<PropLabel align="right">{i18n.PaidToLabel}</PropLabel>
					<PropValue align="left" className="clarity-mask">
						{ClientName}
					</PropValue>
				</Prop>
			</Props>
		</Page>
	)
}

const GridImage = styled(Grid)`
	margin: ${(props) => props.theme.spacing(5)};
`

export {PaymentBeingProcessing}
