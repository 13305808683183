import {Routes, Route} from "react-router-dom"
import {NotFoundPage} from "@indebted/components/Error"
import React from "react"

import {Initiate} from "./Initiate"
import {Questions} from "./Questions"
import {Result} from "./Result"

function AffordabilityAssessmentPage() {
	return (
		<Routes>
			<Route element={<Initiate />} path="/:paymentOptionType/initiate" />
			<Route element={<Questions />} path="/:id" />
			<Route element={<Result />} path="/:id/result" />
			<Route element={<NotFoundPage />} path="*" />
		</Routes>
	)
}

export {AffordabilityAssessmentPage}
