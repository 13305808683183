import React, {useEffect, useState} from "react"
import {Checkbox, Button, Typography, FormControlLabel} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import styled from "styled-components"
import {TextToHTML} from "@indebted/components/TextToHTML"
import {customerAPI} from "@indebted/api"
import {track} from "@indebted/analytics"
import {useSearchParams} from "react-router-dom"

function GrantESIGNConsent({i18n, onSuccess}) {
	const [showESIGNErrorMessage, setShowESIGNErrorMessage] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const [ESIGNErrorMessage, setESIGNErrorMessage] = useState("")
	const [form, setForm] = useState({ESIGNConsent: false})
	const [searchParams] = useSearchParams()
	const customerID = searchParams.get("customerID")

	useEffect(() => {
		track("GrantESIGNConsentFormViewed", {
			CustomerID: customerID,
		})
	}, [customerID])

	const onChangeESIGNConsentCheckbox = ({target: {checked}}) => {
		setForm({...form, ["ESIGNConsent"]: checked})
		setShowESIGNErrorMessage(!checked)
		if (!checked) {
			setESIGNErrorMessage(i18n.ErrorMessage)
		}
	}

	const submit = async (e) => {
		e.preventDefault()

		setSubmitting(true)

		if (!form.ESIGNConsent) {
			setShowESIGNErrorMessage(true)
			setESIGNErrorMessage(i18n.ErrorMessage)
			setSubmitting(false)

			return
		}

		try {
			await customerAPI.grantESIGNConsent({customerID})
			onSuccess()
		} catch (error) {
			setShowESIGNErrorMessage(true)
			setESIGNErrorMessage(error.Message)
		}

		setSubmitting(false)
	}

	return (
		<div>
			<Grid container spacing={7} direction="column" style={{padding: "32px 24px"}}>
				<Grid>
					<Typography variant="h4" style={{color: "#626392", fontWeight: 700}}>
						{i18n.Title}
					</Typography>
				</Grid>
				<Grid>
					<form onSubmit={submit}>
						<Grid container spacing={7} direction="column">
							<Grid>
								<StyledFormControlLabel
									control={
										<StyledCheckbox
											onChange={onChangeESIGNConsentCheckbox}
											name="esign-consent"
											color="primary"
											$error={showESIGNErrorMessage}
										/>
									}
									label={
										<Typography color="textPrimary" style={{fontSize: "14px"}}>
											<TextToHTML text={i18n.CheckboxLabel} />
										</Typography>
									}
								/>
							</Grid>
							{showESIGNErrorMessage && (
								<Grid>
									<StyledAlertGrid>
										<TextToHTML text={ESIGNErrorMessage} />
									</StyledAlertGrid>
								</Grid>
							)}
							<Grid>
								<Button
									variant="contained"
									color="primary"
									type="Submit"
									fullWidth
									disabled={submitting}
								>
									{i18n.ConsentButtonLabel}
								</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</div>
	)
}

const StyledCheckbox = styled(Checkbox)`
	padding-top: 0;

	svg {
		color: ${(props) => (props.$error ? "#D7153A" : "inherit")};
	}
`

const StyledFormControlLabel = styled(FormControlLabel)`
	align-items: start;
`

const StyledAlertGrid = styled(Grid)`
	border: solid 1px #d7153a;
	border-radius: 5px;
	margin-bottom: 10px;
	padding: 10px;

	p {
		font-size: 0.75rem;
		color: #d7153a;
	}
`

export {GrantESIGNConsent}
