import React from "react"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {CalendarPicker, LocalizationProvider} from "@mui/x-date-pickers"

import {locales} from "./locales"

function Calendar({locale, ...props}) {
	return (
		<LocalizationProvider adapterLocale={locales[locale]} dateAdapter={AdapterDateFns}>
			<CalendarPicker {...props} />
		</LocalizationProvider>
	)
}

export {Calendar}
