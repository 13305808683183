import React from "react"
import styled, {keyframes} from "styled-components"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"

import {Page} from "./Page"
import {InDebted} from "./InDebted"

function LoadingPage({I18n}: {I18n?}) {
	return (
		<Page>
			<Grid>
				<LoadingIcon width="10em" />
			</Grid>
			{!!I18n?.PaymentLoadingMessage?.Title && (
				<Grid>
					<Typography align="center" variant="h6">
						{I18n.PaymentLoadingMessage.Title}
					</Typography>
				</Grid>
			)}

			{!!I18n?.PaymentLoadingMessage?.Subtitle && (
				<Grid>
					<Typography align="center">{I18n.PaymentLoadingMessage.Subtitle}</Typography>
				</Grid>
			)}

			{!!I18n?.PaymentPlanLoadingMessage?.Title && (
				<Grid>
					<Typography align="center" variant="h6">
						{I18n.PaymentPlanLoadingMessage.Title}
					</Typography>
				</Grid>
			)}

			{!!I18n?.PaymentPlanLoadingMessage?.Subtitle && (
				<Grid>
					<Typography align="center">{I18n.PaymentPlanLoadingMessage.Subtitle}</Typography>
				</Grid>
			)}
		</Page>
	)
}

function LoadingIcon({width = "3rem"}) {
	return (
		<>
			<AnimatedLoadingIcon>
				<InDebted variant="square" width={width} />
			</AnimatedLoadingIcon>
		</>
	)
}

const loadingIconAnimations = keyframes`
	0% { translateY(0); opacity: 1; }
	10%, 40% { opacity: 0; }
	50% { opacity: 0; transform: translateY(-20%); }
	60% { opacity: 1; transform: translateY(0); }
`

const AnimatedLoadingIcon = styled.div`
	padding: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;

	& rect {
		animation: ${loadingIconAnimations} 2s infinite;
	}

	& rect:nth-child(1) {
		animation-delay: 1s;
	}

	& rect:nth-child(2) {
		animation-delay: 0.5s;
	}

	& rect:nth-child(3) {
		animation-delay: 0s;
	}
`

export {LoadingPage, LoadingIcon}
