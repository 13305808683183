import React from "react"
import {Page} from "@indebted/components/Page"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {Prop, PropLabel, Props, PropValue} from "@indebted/components/Props"
import {TextToHTML} from "@indebted/components/TextToHTML"

function PaymentPlanAutomaticallyCancelled({
	I18n,
	PaymentPlanInstalmentAmount,
	PaymentPlanFrequency,
	PaymentPlanStartDate,
	ClientName,
}) {
	return (
		<Page>
			<Grid>
				<Typography align="center" variant="h6">
					{I18n.AutomaticallyCancelled.Title}
				</Typography>
			</Grid>

			<Grid>
				<TextToHTML text={I18n.AutomaticallyCancelled.Message} className="clarity-mask" />
			</Grid>

			<Grid>
				<Typography>{I18n.AutomaticallyCancelled.DetailsTitle}</Typography>
			</Grid>
			<Props>
				<Prop>
					<PropLabel align="right">{I18n.AutomaticallyCancelled.PlanLabel}</PropLabel>
					<PropValue align="left">
						{PaymentPlanInstalmentAmount} {PaymentPlanFrequency}
					</PropValue>
				</Prop>
				<Prop>
					<PropLabel align="right">{I18n.AutomaticallyCancelled.PaidToLabel}</PropLabel>
					<PropValue align="left" className="clarity-mask">
						{ClientName}
					</PropValue>
				</Prop>
				<Prop>
					<PropLabel align="right">{I18n.AutomaticallyCancelled.StartDateLabel}</PropLabel>
					<PropValue align="left">{PaymentPlanStartDate}</PropValue>
				</Prop>
			</Props>
		</Page>
	)
}
export {PaymentPlanAutomaticallyCancelled}
