import React, {useEffect, useState} from "react"
import {customerAPI, paymentPlanAPI} from "@indebted/api"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"
import {useNotification} from "@indebted/components/Notification"
import {useNavigate, useParams, useSearchParams} from "react-router-dom"
import {track} from "@indebted/analytics"

import {AuDirectDebitForm} from "./AuDirectDebitForm"
import {DebitCardForm} from "./DebitCardForm"
import {DebitCardWithEFTAForm} from "./DebitCardWithEFTAForm"
import {DebitCardWithCPAForm} from "./DebitCardWithCPAForm"
import {UKDirectDebitForm} from "./UKDirectDebitForm"
import {USDirectDebitForm} from "./USDirectDebitForm"

function PaymentForm() {
	const [Component, setComponent] = useState(<LoadingPage />)
	const {secureCode, paymentMethod} = useParams()
	const [params] = useSearchParams()
	const frequency = params.get("frequency")
	const amount = params.get("amount")
	const payday = params.get("payday")
	const startdate = params.get("startdate")
	const aaid = params.get("aaid")

	useEffect(() => {
		paymentPlanAPI
			.create({
				secureCode: secureCode,
				paymentMethod: paymentMethod,
				paymentPlanFrequency: frequency,
				instalmentAmount: parseInt(amount),
				payDay: payday,
				startDate: startdate,
				aaid,
			})
			.then((result) => {
				track("PPFormViewed", {
					SecureCode: secureCode,
				})
				if (result.RequestESIGNConsent) {
					track("PPFormESIGNConsentRequired", {
						SecureCode: secureCode,
					})
				}
				setComponent(<Form type={paymentMethod} {...result} />)
			})
			.catch((error) => {
				setComponent(
					<ErrorPage
						message={error.Message}
						frequency={frequency}
						amount={amount}
						payday={payday}
						startdate={startdate}
						aaid={aaid}
					/>,
				)
			})
	}, [secureCode, paymentMethod, frequency, amount, payday, startdate, aaid])

	return Component
}

function Form({type, ProviderData, Locale, I18n, WalletPayMethods, ...props}) {
	const {secureCode} = useParams()
	const {notification} = useNotification()
	const navigate = useNavigate()

	const callTrack = () => {
		track("PPFormSubmitted", {
			ID: props.PaymentPlanID,
			Frequency: props.PaymentPlanFrequency,
			InstalmentAmount: props.PaymentPlanInstalmentAmount,
			Instalments: props.PaymentPlanInstalments,
			StartDate: props.PaymentPlanStartDate,
			EndDate: props.PaymentPlanEndDate,
			PaymentMethod: props.PaymentMethod,
			SecureCode: secureCode,
		})
	}
	const onSubmit = ({stripeAPI}) => {
		callTrack()
		return customerAPI
			.status({secureCode})
			.then((result) => {
				if (result.Status !== "OutstandingBalance") {
					notification.error("Please refresh your page", 5000)
					return Promise.reject()
				}
				return Promise.resolve()
			})
			.then(() => {
				return stripeAPI
					.confirmSetup(ProviderData.ClientSecret)
					.then((response) => {
						paymentPlanAPI.receiveIntentSubmitted({
							paymentPlanId: props.PaymentPlanID,
							providerName: "Stripe",
							providerRawResponse: response,
						})
						return response
					})
					.then(() => {
						track("PPFormSucceeded", {SecureCode: secureCode})
						navigate(`/${secureCode}/payment-plan-status/${props.PaymentPlanID}`, {
							state: {I18n},
							replace: true,
						})

						return Promise.resolve()
					})
					.catch((error) => {
						track("PPFormFailed", {SecureCode: secureCode})
						notification.error(error.message, 5000)
						return Promise.reject()
					})
			})
	}

	const onSubmitEFTA = async ({
		secureCode,
		stripe,
		providerData,
		paymentPlanID,
		notification,
		i18n,
		paymentMethod,
	}) => {
		try {
			const result = await customerAPI.status({secureCode})
			if (result.Status !== "OutstandingBalance") {
				throw Error("Please refresh your page")
			}

			if (paymentMethod == null) {
				throw Error("Please refresh your page")
			}

			// TODO: test network failure
			const confirmCardSetupResponse = await stripe.confirmCardSetup(providerData.ClientSecret, {
				payment_method: paymentMethod.id,
			})
			if (confirmCardSetupResponse.error) throw Error("Failed to confirm card setup") // TODO: log&track

			// This call is meant to prevent an account belonging to multiple payment plans.
			// Most of the time the call will succeed, therefore the return value is ignored in order to not block the UI flow.
			paymentPlanAPI.receiveIntentSubmitted({
				paymentPlanId: paymentPlanID,
				providerName: "Stripe",
				providerRawResponse: confirmCardSetupResponse,
			})

			track("PPFormSucceeded", {SecureCode: secureCode})
			navigate(`/${secureCode}/payment-plan-status/${paymentPlanID}`, {
				state: {i18n},
				replace: true,
			})

			return Promise.resolve()

			// onSubmit()
		} catch (error) {
			track("PPFormFailed", {SecureCode: secureCode}) // TODO: also track error message
			notification.error(error.message, 5000)
			return Promise.reject()
		}
	}

	const onSubmitCPA = async ({
		secureCode,
		stripe,
		providerData,
		paymentPlanID,
		notification,
		i18n,
		paymentMethod,
	}) => {
		try {
			const result = await customerAPI.status({secureCode})
			if (result.Status !== "OutstandingBalance") {
				throw Error("Please refresh your page")
			}

			if (paymentMethod == null) {
				throw Error("Please refresh your page")
			}

			// TODO: test network failure
			const confirmCardSetupResponse = await stripe.confirmCardSetup(providerData.ClientSecret, {
				payment_method: paymentMethod.id,
			})
			if (confirmCardSetupResponse.error) throw Error("Failed to confirm card setup") // TODO: log&track

			// This call is meant to prevent an account belonging to multiple payment plans.
			// Most of the time the call will succeed, therefore the return value is ignored in order to not block the UI flow.
			paymentPlanAPI.receiveIntentSubmitted({
				paymentPlanId: paymentPlanID,
				providerName: "Stripe",
				providerRawResponse: confirmCardSetupResponse,
			})

			track("PPFormSucceeded", {SecureCode: secureCode})
			navigate(`/${secureCode}/payment-plan-status/${paymentPlanID}`, {
				state: {i18n},
				replace: true,
			})

			return Promise.resolve()

			// onSubmit()
		} catch (error) {
			track("PPFormFailed", {SecureCode: secureCode}) // TODO: also track error message
			notification.error(error.message, 5000)
			return Promise.reject()
		}
	}

	const map = {
		AuDirectDebit: (
			<AuDirectDebitForm
				i18n={I18n.AuDirectDebit}
				discountMessage={I18n.DiscountMessage}
				termsOfDiscountOffer={I18n.TermsOfDiscountOffer}
				locale={Locale}
				onSubmit={onSubmit}
				publishableKey={ProviderData.PublishableKey}
			/>
		),
		DebitCard: (
			<DebitCardForm
				i18n={I18n.DebitCard}
				discountMessage={I18n.DiscountMessage}
				termsOfDiscountOffer={I18n.TermsOfDiscountOffer}
				locale={Locale}
				onSubmit={onSubmit}
				publishableKey={ProviderData.PublishableKey}
				requiresPostcode={props.RequiresPostcode}
				acceptedCardsList={props.AcceptedCards}
				morePaymentMethodsAvailable={props.MorePaymentMethodsAvailable}
				walletPayMethods={WalletPayMethods}
				providerData={ProviderData}
				paymentPlanID={props.PaymentPlanID}
			/>
		),
		DebitCardWithEFTA: (
			<DebitCardWithEFTAForm
				i18n={I18n.DebitCard}
				discountMessage={I18n.DiscountMessage}
				termsOfDiscountOffer={I18n.TermsOfDiscountOffer}
				locale={Locale}
				onSubmitEFTA={onSubmitEFTA}
				publishableKey={ProviderData.PublishableKey}
				requiresPostcode={props.RequiresPostcode}
				acceptedCardsList={props.AcceptedCards}
				morePaymentMethodsAvailable={props.MorePaymentMethodsAvailable}
				walletPayMethods={WalletPayMethods}
				providerData={ProviderData}
				paymentPlanID={props.PaymentPlanID}
				requestESIGNConsent={props.RequestESIGNConsent}
				esignConsent={I18n.ESIGNConsent}
			/>
		),
		UKDirectDebit: (
			<UKDirectDebitForm
				i18n={I18n.UKDirectDebit}
				discountMessage={I18n.DiscountMessage}
				termsOfDiscountOffer={I18n.TermsOfDiscountOffer}
				locale={Locale}
				onSubmit={onSubmit}
				publishableKey={ProviderData.PublishableKey}
			/>
		),
		USDirectDebit: (
			<USDirectDebitForm
				i18n={I18n.USDirectDebit}
				discountMessage={I18n.DiscountMessage}
				locale={Locale}
				onSubmit={onSubmit}
				publishableKey={ProviderData.PublishableKey}
				clientSecret={ProviderData.ClientSecret}
				requestESIGNConsent={props.RequestESIGNConsent}
				esignConsent={I18n.ESIGNConsent}
			/>
		),
		DebitCardWithCPA: (
			<DebitCardWithCPAForm
				i18n={I18n.DebitCard}
				discountMessage={I18n.DiscountMessage}
				termsOfDiscountOffer={I18n.TermsOfDiscountOffer}
				locale={Locale}
				onSubmitCPA={onSubmitCPA}
				publishableKey={ProviderData.PublishableKey}
				requiresPostcode={props.RequiresPostcode}
				acceptedCardsList={props.AcceptedCards}
				morePaymentMethodsAvailable={props.MorePaymentMethodsAvailable}
				walletPayMethods={WalletPayMethods}
				providerData={ProviderData}
				paymentPlanID={props.PaymentPlanID}
				requestESIGNConsent={props.RequestESIGNConsent}
				esignConsent={I18n.ESIGNConsent}
			/>
		),
	}
	return map[props.PaymentMethod] || <ErrorPage message={I18n.PaymentNotSupportedError} />
}

export {PaymentForm}
