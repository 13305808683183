import React, {useEffect, useState} from "react"
import {Typography, Button} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {useNavigate, useParams} from "react-router-dom"
import {track} from "@indebted/analytics"
import {paymentAPI} from "@indebted/api"

function PaymentInFullOptionLink({Title, Subtitle, ButtonLabel, RequiresAffordabilityAssessment, Disclaimer}) {
	const {secureCode} = useParams()
	const navigate = useNavigate()
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null)
	useEffect(() => {
		paymentAPI.getDefaultPaymentMethod({secureCode}).then((result) => {
			setDefaultPaymentMethod(result.DefaultPaymentMethod)
		})
	}, [secureCode])

	const redirect = (RequiresAffordabilityAssessment) => {
		if (RequiresAffordabilityAssessment) {
			navigate(`/${secureCode}/affordability-assessment/payment-in-full/initiate`)
		} else {
			const url = defaultPaymentMethod
				? `/${secureCode}/payment-in-full/${defaultPaymentMethod}`
				: `/${secureCode}/payment-in-full/`
			navigate(url)
		}
	}

	return (
		<Grid container direction="column" alignItems="stretch" spacing={4} style={{flex: 1}}>
			<Grid>
				<Typography variant="h6" align="left">
					{Title}
				</Typography>
			</Grid>
			<Grid>
				<Typography variant="body2" color="textSecondary" align="left">
					{Subtitle}
				</Typography>
			</Grid>
			<Grid>
				<Button
					fullWidth
					disableElevation
					onClick={() => {
						track("PIFButtonClicked", {SecureCode: secureCode})
						redirect(RequiresAffordabilityAssessment)
					}}
				>
					{ButtonLabel}
				</Button>
			</Grid>
			<Grid>
				<Typography variant="body2" color="textSecondary" align="left">
					{Disclaimer}
				</Typography>
			</Grid>
		</Grid>
	)
}

export {PaymentInFullOptionLink}
