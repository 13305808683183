import React from "react"
import styled from "styled-components"
import {TableContainer, Table, TableBody, TableRow, TableCell, Typography} from "@mui/material"

function InstalmentBreakdown({instalmentBreakdownI18n, currency, outstandingBalance, amount, frequency}) {
	const formattedOutstandingBalance = (outstandingBalance / 100).toFixed(2)
	const formattedAmount = amount.toFixed(2)
	const totalInstalments = Math.ceil(outstandingBalance / 100 / amount)

	return (
		<StyledDiv>
			<Typography variant="subtitle2" align="left" style={{fontSize: "1rem"}}>
				{instalmentBreakdownI18n.Header}
			</Typography>
			<TableContainer>
				<StyledTable>
					<TableBody>
						<TableRow>
							<TableCell>{instalmentBreakdownI18n.OutstandingBalance}</TableCell>
							<TableCell align="right">{`${currency} ${formattedOutstandingBalance}`}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								{instalmentBreakdownI18n.InstalmentAmount} ({frequency})
							</TableCell>
							<TableCell align="right">{`${currency} ${formattedAmount}`}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{instalmentBreakdownI18n.TotalInstalments}</TableCell>
							<TableCell align="right">{totalInstalments}</TableCell>
						</TableRow>
					</TableBody>
				</StyledTable>
			</TableContainer>
		</StyledDiv>
	)
}

const StyledDiv = styled.div`
	padding: 0 10px;
`

const StyledTable = styled(Table)`
	margin-top: 0.1rem;

	.MuiTableCell-root {
		padding: 0.5rem;
	}

	.MuiTableCell-root:last-child {
		font-weight: bold;
	}

	.MuiTableRow-root:last-child {
		background-color: rgba(189, 191, 242, 0.34);
		border-bottom: 2px solid rgba(2, 4, 82, 0.46);
	}
`

export {InstalmentBreakdown}
