import React from "react"
import {Page} from "@indebted/components/Page"
import {TextToHTML} from "@indebted/components/TextToHTML"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"

function PaymentPlanInitiatedOrSubmitted({I18n}) {
	return (
		<Page>
			<Grid>
				<Typography align="center" variant="h6">
					{I18n.InitiatedOrSubmitted.Title}
				</Typography>
			</Grid>

			<Grid>
				<TextToHTML align="left" text={I18n.InitiatedOrSubmitted.Message} className="clarity-mask" />
			</Grid>
		</Page>
	)
}
export {PaymentPlanInitiatedOrSubmitted}
