import React from "react"
import styled from "styled-components"
import Grid from "@mui/material/Unstable_Grid2"
import {MastercardLogo, VisaLogo} from "@indebted/assets"

const cardTypesMap = {
	mastercard: MastercardLogo,
	visa: VisaLogo,
}

function mapCardTypesToSvg(cardTypes) {
	return cardTypes.map((type) => cardTypesMap[type]).filter(Boolean)
}

function AcceptedCards({list = []}) {
	const cardSvgs = mapCardTypesToSvg(list)

	return (
		cardSvgs.length > 0 && (
			<Container>
				<Grid container spacing={2} direction="row" alignItems="flex-end" style={{justifyContent: "flex-end"}}>
					<Grid style={{marginRight: "5px"}}>
						<CardLogosContainer>
							{cardSvgs.map((El, idx) => (
								<El key={idx} />
							))}
						</CardLogosContainer>
					</Grid>
				</Grid>
			</Container>
		)
	)
}

const Container = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding-top: 7px;
`

const CardLogosContainer = styled.div`
	* + * {
		margin-left: 5px;
	}
`

export {AcceptedCards}
