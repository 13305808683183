import React, {useEffect, useState} from "react"
import {Button, Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {useNavigate, useLocation, useParams} from "react-router-dom"
import {paymentAPI} from "@indebted/api"
import {ErrorPage} from "@indebted/components/Error"
import {LoadingPage} from "@indebted/components/Loading"
import {Page} from "@indebted/components/Page"
import styled from "styled-components"
import {track} from "@indebted/analytics"
import {AcceptedCards} from "@indebted/components/AcceptedCards"

function PaymentMethodSelection() {
	const {secureCode} = useParams()
	const [Component, setComponent] = useState(<LoadingPage />)
	const navigate = useNavigate()
	const location = useLocation()

	const PaymentMethods = ({paymentMethods, i18n}) => {
		return (
			<Page padded>
				<Grid container direction="column" spacing={5} padding={0} style={{width: "100%"}}>
					<Grid style={{paddingLeft: "0px"}}>
						<Typography variant="h6">{i18n.Header}</Typography>
					</Grid>
					<Grid style={{paddingLeft: "0px"}}>
						<Typography>{i18n.Title}</Typography>
					</Grid>

					<ButtonsGrid container direction="column" alignItems="stretch" padding={0} spacing={5}>
						{paymentMethods.map((method) => (
							<Grid key={method.ID}>
								<Button
									fullWidth
									variant="outlined"
									onClick={() => {
										track("PIFMethodButtonClicked", {
											Method: method.ID,
											SecureCode: secureCode,
										})
										navigate(`/${secureCode}/payment-in-full/${method.ID}${location.search}`)
									}}
								>
									{method.Display}
								</Button>
								<AcceptedCards list={method.AcceptedCards} />
							</Grid>
						))}
					</ButtonsGrid>
				</Grid>
			</Page>
		)
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		paymentAPI
			.createForm({secureCode})
			.then((result) => {
				if (result.PaymentMethodOptions.length === 1) {
					navigate(`/${secureCode}/payment-in-full/${result.PaymentMethodOptions[0].ID}${location.search}`, {
						replace: true,
					})
					return
				}
				track("PIFMethodPageViewed", {
					SecureCode: secureCode,
				})
				setComponent(<PaymentMethods paymentMethods={result.PaymentMethodOptions} i18n={result.I18n} />)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} />)
			})
	}, [secureCode])

	return Component
}

const ButtonsGrid = styled(Grid)`
	margin-top: 20px;
`

export {PaymentMethodSelection}
