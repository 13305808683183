import React from "react"
import styled from "styled-components"
import {Page} from "@indebted/components/Page"
import {Typography, Button} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {track} from "@indebted/analytics"
import {useParams, useSearchParams, useNavigate} from "react-router-dom"

function FortnightlyNextPayDate({i18n, frequency}) {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const weekday = searchParams.get("weekday")
	const options = frequency.Options.filter((option) => option.Weekday == weekday)
	const {secureCode} = useParams()
	track("PPFortnightlyDatePageViewed", {
		SecureCode: secureCode,
	})
	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={5}>
				<Grid>
					<Typography variant="h6">{i18n.DateSelectionHeader}</Typography>
				</Grid>
				<Grid>
					<Typography>{i18n.NextPayDateTitle}</Typography>
				</Grid>

				<ButtonsGrid container direction="column" alignItems="stretch" spacing={5}>
					{options.map((option) => (
						<Grid key={option.ID}>
							<Button
								fullWidth
								variant="outlined"
								onClick={() => {
									track("PPFortnightlyDateButtonClicked", {
										SecureCode: secureCode,
									})
									navigate(`../Amount?${searchParams}&payday=${option.ID}`)
								}}
							>
								{option.Weekday} {option.Date}
							</Button>
						</Grid>
					))}
				</ButtonsGrid>
			</Grid>
		</Page>
	)
}

const ButtonsGrid = styled(Grid)`
	margin-top: 20px;
`

export {FortnightlyNextPayDate}
