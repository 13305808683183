import {Button, Card, CardHeader} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {ClockIcon, ReoccurringDollarIcon} from "@indebted/assets"
import {colors} from "@indebted/theme"
import React from "react"
import {useNavigate} from "react-router-dom"

import {
	CardContentLessPadding,
	CardHeadingTypography,
	ColoredTypography,
	ColoredTypographyWithBottomMargin,
	GridWithBottomMargin,
	StyledDiv,
} from "./styles"

const PaymentPlanInProgressPage = ({I18n, amount}) => {
	const navigate = useNavigate()

	return (
		<Grid container direction="column" alignItems="center" justifyContent="center">
			<Grid container direction="row" alignItems="center" justifyContent="center" style={{marginTop: "25px"}}>
				<ClockIcon />
			</Grid>
			<Grid container direction="row" alignItems="center" justifyContent="center" style={{marginBottom: "10px"}}>
				<ColoredTypographyWithBottomMargin
					variant="h5"
					color={colors.stratosLight2}
					align="center"
					style={{padding: "0 64px", fontWeight: 700, lineHeight: "28px"}}
				>
					{I18n.PaymentInProgress.Title}
				</ColoredTypographyWithBottomMargin>
			</Grid>
			<Grid container direction="row" alignItems="center" justifyContent="center" style={{marginBottom: "34px"}}>
				<ColoredTypographyWithBottomMargin
					variant="subtitle1"
					color={colors.stratos}
					align="center"
					style={{padding: "0 64px", lineHeight: "22px"}}
				>
					{I18n.PaymentInProgress.Subtitle}
				</ColoredTypographyWithBottomMargin>
			</Grid>

			<Grid
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				style={{padding: "10px 20px", marginBottom: "35px"}}
			>
				<Card
					style={{
						width: "100%",
						marginBottom: "20px",
						boxShadow: "0px 11px 22px 0px rgba(25,89,135,0.2)",
						background: "#fafafa",
					}}
				>
					<CardHeader
						title={
							<CardHeadingTypography color={colors.stratos}>
								{I18n.PaymentInProgress.PaymentDetailsCardTitle}
							</CardHeadingTypography>
						}
					/>
					<CardContentLessPadding>
						<GridWithBottomMargin container direction="row" justifyContent="space-between">
							<StyledDiv>
								<ColoredTypography variant="subtitle1" color={colors.stratosLight2}>
									{I18n.PaymentInProgress.PaymentDetailsCardPaymentAmountLabel}
								</ColoredTypography>
							</StyledDiv>
							<StyledDiv>
								<ColoredTypography variant="subtitle1" color={colors.stratosLight2}>
									{amount}
								</ColoredTypography>
							</StyledDiv>
						</GridWithBottomMargin>
					</CardContentLessPadding>
				</Card>
			</Grid>

			<Button
				style={{borderRadius: "35px", padding: "6px 30px", marginTop: "10px"}}
				type="button"
				onClick={() => {
					navigate("./")
					navigate(0)
				}}
				variant="contained"
				startIcon={<ReoccurringDollarIcon />}
			>
				{I18n.PaymentInProgress.ButtonText}
			</Button>
		</Grid>
	)
}

export {PaymentPlanInProgressPage}
