import React, {useEffect, useState} from "react"
import {ErrorPage} from "@indebted/components/Error"
import {LoadingPage} from "@indebted/components/Loading"
import {customerAPI} from "@indebted/api"
import {track} from "@indebted/analytics"
import {useParams} from "react-router-dom"

import {CustomerStatuses} from "./CustomerStatuses"
import {SecureCodeNotFound} from "./SecureCodeNotFound"

function SecureCodeLandingPage() {
	const {secureCode} = useParams()
	const [Component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		customerAPI
			.find({secureCode})
			.then((result) => {
				track("LandingPageViewed", {
					SecureCode: secureCode,
					CustomerStatus: result.Status,
				})
				setComponent(<CustomerStatuses status={result.Status} payload={result.Payload} i18n={result.I18n} />)
			})
			.catch((error) => {
				const errorMap = {
					404: <SecureCodeNotFound i18n={error.i18n} secureCode={secureCode} />,
					500: <ErrorPage message={error.Message} />,
				}

				setComponent(errorMap[error.Status] || errorMap[500])
			})
	}, [secureCode])

	return Component
}

export {SecureCodeLandingPage}
