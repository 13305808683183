import React from "react"
import {Typography, Link} from "@mui/material"

function Disclaimer() {
	return (
		<Typography variant="caption" color="textSecondary" component="p" align="justify">
			By providing your bank account details and confirming this payment, you agree to this Direct Debit Request
			and the&nbsp;
			<Link
				href="https://www.indebted.co/policies/au-becs-dd-service-agreement"
				target="_blank"
				rel="noopener noreferrer"
			>
				Direct Debit Request service agreement
			</Link>
			, and authorise Stripe Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User ID number 507156
			(&apos;Stripe&apos;) to debit your account through the Bulk Electronic Clearing System (BECS) on behalf of
			InDebted Australia Pty Ltd (the &apos;Merchant&apos;) for any amounts separately communicated to you by the
			Merchant. You certify that you are either an account holder or an authorised signatory on the account listed
			above.
		</Typography>
	)
}

export {Disclaimer}
