import React from "react"
import {Routes, Route} from "react-router-dom"

import {PaymentMethodSelection} from "./PaymentMethodSelection"
import {PaymentForm} from "./PaymentForm"
import {DefaultPaymentMethodPage} from "./DefaultPaymentMethodPage"

function PaymentInFullPage() {
	return (
		<Routes>
			<Route element={<DefaultPaymentMethodPage />} path="/" />
			<Route element={<PaymentMethodSelection />} path="/PaymentMethodSelection" />
			<Route element={<PaymentForm />} path="/:paymentMethod" />
		</Routes>
	)
}

export {PaymentInFullPage}
