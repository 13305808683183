import {MenuItem, TextField} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import React, {useState} from "react"

function SimpleInput({input, question, subQuestion}) {
	const [value, setValue] = useState("")
	const pattern = /\D+/
	const mask = {
		number: {
			onChange: (e) => {
				setValue(e.target.value.replace(pattern, ""))
			},
		},
	}[input.Accepts] || {
		onChange: (e) => {
			setValue(e.target.value)
		},
	}
	return (
		<TextField
			name={`${input.Name}-${question.Number}-${subQuestion.Number}`}
			variant="standard"
			label={input.Placeholder}
			type="text"
			value={value}
			{...mask}
		/>
	)
}

function Dropdown({question, subQuestion, input}) {
	return (
		<TextField
			select
			variant="standard"
			name={`${input.Name}-${question.Number}-${subQuestion.Number}`}
			defaultValue={input.Values[input.Values.length - 1].Value}
		>
			{input.Values.map((value, index) => {
				return (
					<MenuItem value={value.Value} key={index}>
						{value.Label}
					</MenuItem>
				)
			})}
		</TextField>
	)
}

function SelectedInput({question, subQuestion, input}) {
	const components = {
		input: SimpleInput,
		dropdown: Dropdown,
	}

	const Component = components[input.Type]

	return <Component question={question} subQuestion={subQuestion} input={input} />
}

function MultiInput({question, subQuestion}) {
	return (
		<Grid container direction="row" spacing={3} alignItems="flex-end">
			<Grid xs={4} style={{textAlign: "right"}}>
				{subQuestion.Label}:
			</Grid>
			<Grid xs={8}>
				<Grid container direction="row" justifyContent="space-evenly" alignItems="flex-end">
					<input type="hidden" name="questionNumber" value={question.Number} />
					<input type="hidden" name="questionPropertiesNumber" value={subQuestion.Number} />
					{subQuestion.Inputs.map((input, index) => {
						return (
							<Grid key={index} xs={4} padding={0}>
								<SelectedInput input={input} subQuestion={subQuestion} question={question} />
							</Grid>
						)
					})}
				</Grid>
			</Grid>
		</Grid>
	)
}

export {MultiInput}
