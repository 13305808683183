import React, {useRef} from "react"

import {SubQuestion} from "./SubQuestion"

function MultiQuestion({question, answers, onAnswer}) {
	const form = useRef(null)

	return (
		<form ref={form}>
			{question.SubQuestions.map((subQuestion) => {
				return (
					<SubQuestion
						key={question.Number + "_" + subQuestion.Number}
						question={question}
						subQuestion={subQuestion}
						onAnswer={onAnswer}
						answers={answers}
						form={form}
					/>
				)
			})}
		</form>
	)
}

export {MultiQuestion}
