import {Button} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import React from "react"
import styled from "styled-components"

function SelectQuestion({question, answers, onAnswer}) {
	return (
		<ButtonsGrid direction="column" alignItems="stretch" spacing={5}>
			{question.Options.map((option) => (
				<Grid key={option.Label}>
					<Button
						fullWidth
						variant="outlined"
						onClick={() =>
							onAnswer(option, [
								...answers,
								{QuestionNumber: question.Number, OptionNumber: option.Number},
							])
						}
					>
						{option.Label}
					</Button>
				</Grid>
			))}
		</ButtonsGrid>
	)
}

const ButtonsGrid = styled(Grid).attrs({container: true})`
	margin-top: 20px;
	flex: 1;
`

export {SelectQuestion}
