import React from "react"
import {Page} from "@indebted/components/Page"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {PaymentProcessingImage} from "@indebted/assets"
import styled from "styled-components"
import {TextToHTML} from "@indebted/components/TextToHTML"

function UKDirectDebitProcessing({I18n}) {
	return (
		<Page>
			<Grid>
				<Typography align="center" variant="h6">
					{I18n.UKDirectDebit.ProcessingTitle}
				</Typography>
			</Grid>

			<Grid>
				<TextToHTML align="center" text={I18n.UKDirectDebit.ProcessingSubtitle} />
			</Grid>

			<GridImage>
				<PaymentProcessingImage />
			</GridImage>
		</Page>
	)
}

const GridImage = styled(Grid)`
	margin: ${(props) => props.theme.spacing(5)};
`

export {UKDirectDebitProcessing}
