import React from "react"
import {Typography, Button} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {useNavigate, useParams} from "react-router-dom"
import {track} from "@indebted/analytics"

function PartialPaymentOptionLink({Title, Subtitle, ButtonLabel, Disclaimer}) {
	const {secureCode} = useParams()
	const navigate = useNavigate()

	track("PartialPaymentOptionOffered", {SecureCode: secureCode})

	return (
		<Grid container direction="column" alignItems="stretch" spacing={4} style={{flex: 1}}>
			<Grid>
				<Typography variant="h6" align="left">
					{Title}
				</Typography>
			</Grid>
			<Grid>
				<Typography variant="body2" color="textSecondary" align="left">
					{Subtitle}
				</Typography>
			</Grid>
			<Grid>
				<Button
					fullWidth
					disableElevation
					onClick={() => {
						track("PartialPaymentButtonClicked", {SecureCode: secureCode})
						navigate(`/${secureCode}/partial-payment`)
					}}
				>
					{ButtonLabel}
				</Button>
			</Grid>
			<Grid>
				<Typography variant="body2" color="textSecondary" align="left">
					{Disclaimer}
				</Typography>
			</Grid>
		</Grid>
	)
}

export {PartialPaymentOptionLink}
