import React, {useState} from "react"
import {useNotification} from "@indebted/components/Notification"

import {Question} from "./Question"

function Steps({questions, onSubmit, id}) {
	const [Component, setComponent] = useState(<Question question={questions[0]} answers={[]} onAnswer={onAnswer} />)

	const {notification} = useNotification()
	function onAnswer(option, answers) {
		const lastAnswers = answers[answers.length - 1]
		if (option.Number === 1 && lastAnswers.SubAnswers !== undefined) {
			const subAnswers = lastAnswers.SubAnswers
			const hasAnswersAtLeastOne = subAnswers.some((subAnswer) => subAnswer.Amount !== "")
			if (!hasAnswersAtLeastOne) {
				notification.error("Please answer at least one question", 5000)
				return
			}
		}
		if (option.NextQuestion) {
			setComponent(
				<Question
					question={questions.find((question) => question.Number === option.NextQuestion)}
					answers={answers}
					onAnswer={onAnswer}
				/>,
			)
		} else {
			onSubmit({answers, id})
		}
	}

	return Component
}

export {Steps}
