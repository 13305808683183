import React, {useEffect, useState} from "react"
import {customerAPI, paymentAPI} from "@indebted/api"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"
import {useNotification} from "@indebted/components/Notification"
import {useNavigate, useParams, useSearchParams} from "react-router-dom"
import {track} from "@indebted/analytics"

import {AuDirectDebitForm} from "./AuDirectDebitForm"
import {DebitCardForm} from "./DebitCardForm"
import {DebitCardWithEFTAForm} from "./DebitCardWithEFTAForm"
import {UKDirectDebitForm} from "./UKDirectDebitForm"
import {USDirectDebitForm} from "./USDirectDebitForm"

function PaymentForm() {
	const {secureCode, paymentMethod} = useParams()
	const [Component, setComponent] = useState(<LoadingPage />)
	const [searchParams] = useSearchParams()
	const affordabilityAssessmentID = searchParams.get("aaid")

	useEffect(() => {
		paymentAPI
			.create({secureCode, paymentMethod, affordabilityAssessmentID})
			.then((result) => {
				track("PIFFormViewed", {
					SecureCode: secureCode,
				})
				setComponent(<Form type={result.PaymentMethod} {...result} StatusURL="payment-in-full-status" />)
			})
			.catch((error) => {
				setComponent(
					<ErrorPage message={error.Message} affordabilityAssessmentID={affordabilityAssessmentID} />,
				)
			})
	}, [secureCode, paymentMethod, affordabilityAssessmentID])

	return Component
}

function Form({
	type,
	ProviderData,
	PaymentID,
	PaymentAmountDisplay,
	MorePaymentMethodsAvailable,
	PaymentAmount,
	PaymentCurrency,
	Locale,
	RequiresPostcode,
	I18n,
	AcceptedCards,
	CustomerCountryISOAlpha,
	StatusURL,
	WalletPayMethods,
	WalletPayLabel,
	UseNewDigitalWalletFlow,
}) {
	const {notification} = useNotification()
	const {secureCode} = useParams()
	const navigate = useNavigate()

	const onSubmit = ({stripeAPI}) => {
		track("PIFFormSubmitted", {
			ID: PaymentID,
			Amount: PaymentAmountDisplay,
			PaymentMethod: type,
			SecureCode: secureCode,
		})

		return stripeAPI
			.confirmPayment(ProviderData.ClientSecret)
			.then(() => {
				track("PIFFormSucceeded", {SecureCode: secureCode})
				navigate(`/${secureCode}/${StatusURL}/${PaymentID}`, {state: {I18n, PaymentMethod: type}})

				return Promise.resolve()
			})
			.catch((error) => {
				track("PIFFormFailed", {SecureCode: secureCode})
				notification.error(error.message, 5000)

				return Promise.reject(error)
			})
	}

	const onSubmitEFTA = async ({secureCode, stripe, providerData, notification, paymentMethod}) => {
		try {
			const result = await customerAPI.status({secureCode})
			if (result.Status !== "OutstandingBalance") {
				throw Error("Please refresh your page")
			}

			if (paymentMethod == null) {
				throw Error("Please refresh your page")
			}

			// TODO: test network failure
			const confirmCardPaymentResponse = await stripe.confirmCardPayment(providerData.ClientSecret, {
				payment_method: paymentMethod.id,
			})
			if (confirmCardPaymentResponse.error) throw Error("Failed to confirm card setup") // TODO: log&track

			track("PIFFormSucceeded", {SecureCode: secureCode})
			navigate(`/${secureCode}/${StatusURL}/${PaymentID}`, {state: {I18n, PaymentMethod: type}})

			return Promise.resolve()

			// onSubmit()
		} catch (error) {
			track("PIFFormFailed", {SecureCode: secureCode})
			notification.error(error.message, 5000)
			return Promise.reject()
		}
	}

	const createPaymentRequest = ({stripeAPI}) => {
		const paymentRequest = stripeAPI.createPaymentRequest(
			PaymentAmount,
			PaymentCurrency,
			CustomerCountryISOAlpha,
			WalletPayLabel,
		)
		return {paymentRequest, PaymentID}
	}

	const map = {
		AuDirectDebit: (
			<AuDirectDebitForm
				i18n={I18n.AuDirectDebit}
				locale={Locale}
				onSubmit={onSubmit}
				publishableKey={ProviderData.PublishableKey}
			/>
		),
		DebitCard: (
			<DebitCardForm
				i18n={I18n.DebitCard}
				locale={Locale}
				onSubmit={onSubmit}
				publishableKey={ProviderData.PublishableKey}
				requiresPostcode={RequiresPostcode}
				acceptedCardsList={AcceptedCards}
				morePaymentMethodsAvailable={MorePaymentMethodsAvailable}
				walletPay={createPaymentRequest}
				clientSecret={ProviderData.ClientSecret}
				walletPayMethods={WalletPayMethods}
				eventPrefix="PIF"
				statusURL={StatusURL}
				requiresEftaDisclosure={false}
			/>
		),
		DebitCardWithEFTA: (
			<DebitCardWithEFTAForm
				i18n={I18n.DebitCard}
				locale={Locale}
				onSubmitEFTA={onSubmitEFTA}
				publishableKey={ProviderData.PublishableKey}
				requiresPostcode={RequiresPostcode}
				acceptedCardsList={AcceptedCards}
				morePaymentMethodsAvailable={MorePaymentMethodsAvailable}
				walletPay={createPaymentRequest}
				clientSecret={ProviderData.ClientSecret}
				providerData={ProviderData}
				walletPayMethods={WalletPayMethods}
				eventPrefix="PIF"
				statusURL={StatusURL}
				useNewDigitalWalletFlow={UseNewDigitalWalletFlow}
			/>
		),
		UKDirectDebit: (
			<UKDirectDebitForm
				i18n={I18n.UKDirectDebit}
				locale={Locale}
				onSubmit={onSubmit}
				publishableKey={ProviderData.PublishableKey}
			/>
		),
		USDirectDebit: (
			<USDirectDebitForm
				i18n={I18n.USDirectDebit}
				locale={Locale}
				onSubmit={onSubmit}
				publishableKey={ProviderData.PublishableKey}
				clientSecret={ProviderData.ClientSecret}
			/>
		),
	}

	return map[type] || <ErrorPage message={I18n.PaymentNotSupportedError} />
}

export {PaymentForm}
