import React, {useEffect, useState} from "react"
import {Routes, Route, useParams} from "react-router-dom"
import {LoadingPage} from "@indebted/components/Loading"
import {paymentPlanAPI} from "@indebted/api"
import {ErrorPage} from "@indebted/components/Error"

import {FrequencySelection} from "./FrequencySelection"
import {WeeklyPayDay} from "./DateSelection/WeeklyPayDay"
import {FortnightlyPayDay} from "./DateSelection/FortnightlyPayDay"
import {FortnightlyNextPayDate} from "./DateSelection/FortnightlyNextPayDate"
import {MonthlyPayDay} from "./DateSelection/MonthlyPayDay"
import {CalendarCustomStartDate} from "./DateSelection/CustomStartDate"
import {AmountSelectionPage} from "./AmountSelection"
import {PaymentMethodSelection} from "./PaymentMethodSelection"
import {PaymentForm} from "./PaymentForm"

function PaymentPlanForm({options}) {
	const frequencyByID = (ID) => options.FrequencyOptions.find((f) => f.ID === ID)
	return (
		<>
			<Routes>
				<Route
					element={
						<FrequencySelection
							i18n={options.I18n.FrequencySelection}
							frequencies={options.FrequencyOptions}
						/>
					}
					path="/"
				/>
				<Route
					element={
						<WeeklyPayDay
							frequency={frequencyByID("Weekly")}
							i18n={{DateSelection: options.I18n.DateSelection, PayToday: options.I18n.PayToday}}
							todayStartDateEnabled={options.TodayStartDateEnabled}
						/>
					}
					path="/Weekly"
				/>
				<Route
					element={
						<FortnightlyPayDay
							frequency={frequencyByID("Fortnightly")}
							i18n={{DateSelection: options.I18n.DateSelection, PayToday: options.I18n.PayToday}}
							todayStartDateEnabled={options.TodayStartDateEnabled}
						/>
					}
					path="/Fortnightly"
				/>
				<Route
					element={
						<FortnightlyNextPayDate
							frequency={frequencyByID("Fortnightly")}
							i18n={options.I18n.DateSelection}
						/>
					}
					path="/FortnightlyNextPayDate"
				/>
				<Route
					element={
						<MonthlyPayDay
							frequency={frequencyByID("Monthly")}
							i18n={{DateSelection: options.I18n.DateSelection, PayToday: options.I18n.PayToday}}
							todayStartDateEnabled={options.TodayStartDateEnabled}
						/>
					}
					path="/Monthly"
				/>
				<Route
					element={
						<CalendarCustomStartDate
							i18n={options.I18n.DateSelection}
							locale={options.Locale}
							frequencies={options.FrequencyOptions}
						/>
					}
					path="/CustomStartDate"
				/>
				<Route element={<AmountSelectionPage frequencyOptions={options.FrequencyOptions} />} path="/Amount" />
				<Route
					element={
						<PaymentMethodSelection
							i18n={options.I18n.PaymentMethodSelection}
							paymentMethods={options.PaymentMethodOptions}
						/>
					}
					path="/PaymentMethodSelection"
				/>
				<Route element={<PaymentForm />} path="/:paymentMethod" />
			</Routes>
		</>
	)
}

function PaymentPlanPage() {
	const {secureCode} = useParams()
	const [Component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		paymentPlanAPI
			.createForm({secureCode})
			.then((result) => {
				setComponent(<PaymentPlanForm options={result} />)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} />)
			})
	}, [secureCode])

	return Component
}

export {PaymentPlanPage}
