import React from "react"
import {Page} from "@indebted/components/Page"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {StripeDebitCardWithEFTAForm} from "@indebted/stripe"
import {useLocation} from "react-router-dom"

function DebitCardWithEFTAForm({
	i18n,
	locale,
	onSubmitEFTA,
	publishableKey,
	requiresPostcode,
	acceptedCardsList,
	morePaymentMethodsAvailable,
	alternativePaymentMethod,
	walletPay,
	walletPayMethods,
	clientSecret,
	eventPrefix,
	statusURL,
	providerData,
	useNewDigitalWalletFlow,
}) {
	const location = useLocation()

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" spacing={5} padding={0}>
				<Grid>
					<Typography variant="h6">{i18n.Header}</Typography>
				</Grid>
				<Grid>
					<Typography>{i18n.Title}</Typography>
				</Grid>

				<Grid>
					<StripeDebitCardWithEFTAForm
						i18n={i18n}
						locale={locale}
						onSubmitEFTA={onSubmitEFTA}
						publishableKey={publishableKey}
						hidePostalCode={!requiresPostcode}
						acceptedCardsList={acceptedCardsList}
						alternativePaymentMethod={alternativePaymentMethod}
						morePaymentMethodsAvailable={morePaymentMethodsAvailable}
						walletPay={walletPay}
						walletPayMethods={walletPayMethods}
						clientSecret={clientSecret}
						providerData={providerData}
						paymentMethodSelectionLink={`PaymentMethodSelection/${location.search}`}
						eventPrefix={eventPrefix}
						statusURL={statusURL}
						useNewDigitalWalletFlow={useNewDigitalWalletFlow}
					/>
				</Grid>
			</Grid>
		</Page>
	)
}

export {DebitCardWithEFTAForm}
