import React from "react"
import {Page} from "@indebted/components/Page"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {StripeUKBankAccountForm} from "@indebted/stripe"
import {useLocation} from "react-router-dom"

function UKDirectDebitForm({i18n, locale, onSubmit, publishableKey}) {
	const location = useLocation()

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" spacing={5} padding={0}>
				<Grid>
					<Typography variant="h6">{i18n.Header}</Typography>
				</Grid>
				<Grid>
					<Typography>{i18n.Title}</Typography>
				</Grid>
				<Grid>
					<Typography>{i18n.FormProcessingDurationDisclaimer}</Typography>
				</Grid>

				<Grid>
					<StripeUKBankAccountForm
						i18n={i18n}
						locale={locale}
						onSubmit={onSubmit}
						publishableKey={publishableKey}
						paymentMethodSelectionLink={`PaymentMethodSelection/${location.search}`}
					/>
				</Grid>
			</Grid>
		</Page>
	)
}

export {UKDirectDebitForm}
