import React from "react"
import {Page} from "@indebted/components/Page"
import Grid from "@mui/material/Unstable_Grid2"
import {StripeUSBankAccountForm} from "@indebted/stripe"
import {useLocation} from "react-router-dom"

function USDirectDebitForm({
	i18n,
	discountMessage,
	locale,
	publishableKey,
	clientSecret,
	onSubmit,
	requestESIGNConsent,
	esignConsent,
}) {
	const location = useLocation()

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" spacing={5} padding={0}>
				<Grid>
					<StripeUSBankAccountForm
						i18n={i18n}
						discountMessage={discountMessage}
						locale={locale}
						publishableKey={publishableKey}
						clientSecret={clientSecret}
						onSubmit={onSubmit}
						onCollect={(stripe, stripeObj) =>
							stripe.collectBankAccountForSetup(stripeObj).then((result) => {
								return {intent: result.setupIntent, error: result.error}
							})
						}
						paymentMethodSelectionLink={`PaymentMethodSelection${location.search}`}
						requestESIGNConsent={requestESIGNConsent}
						esignConsent={esignConsent}
					/>
				</Grid>
			</Grid>
		</Page>
	)
}

export {USDirectDebitForm}
