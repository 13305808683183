import React from "react"
import styled from "styled-components"
import {Page} from "@indebted/components/Page"
import {Typography, Button} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {track} from "@indebted/analytics"
import {useParams, useSearchParams, useNavigate} from "react-router-dom"

function FrequencySelection({i18n, frequencies}) {
	const navigate = useNavigate()
	const [params] = useSearchParams()
	const {secureCode} = useParams()
	track("PPFrequencyPageViewed", {
		SecureCode: secureCode,
	})
	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" justifyContent="space-between">
				<Grid padding={0}>
					<Grid container direction="column" alignItems="stretch" spacing={5}>
						<Grid>
							<Typography variant="h6">{i18n.Header}</Typography>
						</Grid>
						<Grid>
							<Typography>{i18n.Title}</Typography>
						</Grid>
					</Grid>

					<ButtonsGrid container direction="column" alignItems="stretch" spacing={5}>
						<Grid>
							<Typography>{i18n.Question}</Typography>
						</Grid>

						{frequencies.map((frequency) => (
							<Grid key={frequency.ID}>
								<Button
									fullWidth
									variant="outlined"
									onClick={() => {
										track("PPFrequencyButtonClicked", {
											Frequency: frequency.ID,
											SecureCode: secureCode,
										})
										navigate(`${frequency.ID}?${params}`)
									}}
								>
									{frequency.DisplayText}
								</Button>
							</Grid>
						))}
					</ButtonsGrid>
				</Grid>
			</Grid>
		</Page>
	)
}

const ButtonsGrid = styled(Grid)`
	margin-top: 20px;
`

export {FrequencySelection}
