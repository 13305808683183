import React from "react"
import {Page} from "@indebted/components/Page"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import styled from "styled-components"
function Rejected() {
	return (
		<Page>
			<Grid style={{width: "100%"}}>
				<Typography variant="body2">
					Thank you for discussing this with us. At this time, a payment arrangement doesn&apos;t appear to be
					the best solution for your situation.
				</Typography>
			</Grid>

			<Grid style={{width: "100%"}}>
				<Typography variant="body2">
					In circumstances like yours, we recommend seeking independent debt advice.
				</Typography>
			</Grid>

			<Grid style={{width: "100%"}}>
				<Typography variant="body2">
					To give you some breathing space and the opportunity to access free and impartial advice, we will
					place your account on hold for one month.
				</Typography>
			</Grid>

			<Grid>
				<Typography variant="h6" align="center">
					Recommended Resources:
				</Typography>
			</Grid>

			<Grid>
				<Typography variant="h6" align="center">
					StepChange
				</Typography>
				<Typography variant="body2" align="center">
					0800 138 1111
				</Typography>
				<Typography variant="body2" align="center">
					<a href="https://www.stepchange.org">www.stepchange.org</a>
				</Typography>
			</Grid>

			<Grid>
				<Typography variant="h6" align="center">
					National Debtline
				</Typography>
				<Typography variamnt="body2" align="center">
					0800 808 4000
				</Typography>
				<Typography variant="body2" align="center">
					<a href="https://www.nationaldebtline.org">www.nationaldebtline.org</a>
				</Typography>
			</Grid>

			<Grid>
				<Typography variant="h6" align="center">
					Citizens Advice Bureau
				</Typography>
				<Typography variant="body2" align="center">
					<a href="https://www.citizensadvice.org.uk">www.citizensadvice.org.uk</a>
				</Typography>
			</Grid>

			<CompanyContactInformation>
				<Grid container direction="column" spacing={5}>
					<Grid>
						<Typography variant="body2" style={{width: "100%"}}>
							If you would like to discuss this further or believe this information is incorrect, please
							contact us using the details below:
						</Typography>
					</Grid>

					<Grid>
						<Typography variant="h6" align="center">
							InDebted Platform Co UK Ltd
						</Typography>
					</Grid>

					<Grid>
						<Typography variant="body2" align="center">
							<b>Call Us</b>
						</Typography>
						<Typography variant="body2" align="center">
							+44 808 196 6788 (Toll-free)
						</Typography>
					</Grid>

					<Grid>
						<Typography variant="body2" align="center">
							<b>SMS</b>
						</Typography>
						<Typography variant="body2" align="center">
							+44 7723 421654
						</Typography>
					</Grid>

					<Grid>
						<Typography variant="body2" align="center">
							<b>Live Chat</b>
						</Typography>
						<Typography variant="body2" align="center">
							Click the blue chat icon in the bottom-right corner.
						</Typography>
					</Grid>
				</Grid>
			</CompanyContactInformation>
		</Page>
	)
}

const CompanyContactInformation = styled(Grid)`
	background-color: rgb(242, 243, 246);
	margin: 10px;
`

export {Rejected}
