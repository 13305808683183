import * as dateFnsLocales from "date-fns/locale"

const locales = {
	"en-US": dateFnsLocales["enUS"],
	"en-AU": dateFnsLocales["enAU"],
	"en-NZ": dateFnsLocales["enNZ"],
	"en-CA": dateFnsLocales["enCA"],
	"en-GB": dateFnsLocales["enGB"],
	"fr-CA": dateFnsLocales["frCA"],
	"es-US": dateFnsLocales["es"],
	"es-MX": dateFnsLocales["es"],
}

export {locales}
