import React from "react"
import {Page} from "@indebted/components/Page"
import {DiscountMessage} from "@indebted/components/DiscountMessage"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {StripeDebitCardForm} from "@indebted/stripe"
import {useLocation} from "react-router-dom"

function DebitCardForm({
	i18n,
	discountMessage,
	termsOfDiscountOffer,
	locale,
	onSubmit,
	publishableKey,
	requiresPostcode = false,
	acceptedCardsList,
	morePaymentMethodsAvailable,
	walletPayMethods,
	providerData,
	paymentPlanID,
}) {
	const location = useLocation()

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" spacing={5} padding={0}>
				<Grid>
					<DiscountMessage discountMessage={discountMessage} />
				</Grid>
				<Grid>
					<Typography variant="h6">{i18n.Header}</Typography>
				</Grid>
				<Grid>
					<Typography>{i18n.Title}</Typography>
				</Grid>

				<Grid>
					<StripeDebitCardForm
						i18n={i18n}
						termsOfDiscountOffer={termsOfDiscountOffer}
						locale={locale}
						onSubmit={onSubmit}
						publishableKey={publishableKey}
						hidePostalCode={!requiresPostcode}
						acceptedCardsList={acceptedCardsList}
						morePaymentMethodsAvailable={morePaymentMethodsAvailable}
						walletPayMethods={walletPayMethods}
						paymentMethodSelectionLink={`PaymentMethodSelection${location.search}`}
						providerData={providerData}
						paymentPlanID={paymentPlanID}
					/>
				</Grid>
			</Grid>
		</Page>
	)
}

export {DebitCardForm}
