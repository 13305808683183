import React from "react"
import {Routes, Route} from "react-router-dom"

import {PaymentMethodSelection} from "./PaymentMethodSelection"
import {PartialPaymentForm} from "./PaymentForm"
import {AmountSelectionPage} from "./AmountSelection"

function PartialPaymentPage() {
	return (
		<Routes>
			<Route element={<AmountSelectionPage />} path="/" />
			<Route element={<PaymentMethodSelection />} path="/PaymentMethodSelection" />
			<Route element={<PartialPaymentForm />} path="/:paymentMethod" />
		</Routes>
	)
}

export {PartialPaymentPage}
