import React from "react"
import {createRoot} from "react-dom/client"
import {BrowserRouter, Routes, Route} from "react-router-dom"
import {InDebtedCoRoute} from "@indebted/components/InDebtedCoRoute"
import {NotFoundPage} from "@indebted/components/Error"
import {ThemeProvider} from "styled-components"
import {theme} from "@indebted/theme"
import {Layout} from "@indebted/components/Layout"
import {CssBaseline, ThemeProvider as MuiThemeProvider} from "@mui/material"
import {NotificationProvider} from "@indebted/components/Notification"

import {Pages} from "./Pages"
import {OptOutPage} from "./OptOutPage"
import {TransactionalEmailOptOutPage} from "./TransactionalEmailOptOutPage"
import {NotificationOfAssignmentAcknowledgementPage} from "./NotificationOfAssignmentAcknowledgementPage"
import {PaymentPlanStatusWithRegionPage} from "./Pages/PaymentPlanStatusPage"
import {GrantESIGNConsentPage} from "./GrantESIGNConsentPage"

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
	<MuiThemeProvider theme={theme}>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<NotificationProvider>
				<Layout>
					<BrowserRouter>
						<Routes>
							<Route element={<InDebtedCoRoute />} path="/" />

							<Route element={<OptOutPage />} path="/opt-out/:token/:channel/*" />
							<Route
								element={<TransactionalEmailOptOutPage />}
								path="/transactional-email-opt-out/:token"
							/>
							<Route element={<NotificationOfAssignmentAcknowledgementPage />} path="/accounts/ack-noa" />
							<Route element={<GrantESIGNConsentPage />} path="customers/grant-esign-consent/*" />
							<Route
								element={<GrantESIGNConsentPage />}
								path="customers/:regionPrefix/grant-esign-consent/*"
							/>
							<Route
								element={<PaymentPlanStatusWithRegionPage region={0} />}
								path="/0/payment-plan/:paymentPlanID"
							/>
							<Route
								element={<PaymentPlanStatusWithRegionPage region={1} />}
								path="/1/payment-plan/:paymentPlanID"
							/>
							<Route element={<Pages />} path="/:secureCode/*" />
							<Route element={<NotFoundPage />} path="*" />
						</Routes>
					</BrowserRouter>
				</Layout>
			</NotificationProvider>
		</ThemeProvider>
	</MuiThemeProvider>,
)
