import React, {useEffect, useState} from "react"
import {Page} from "@indebted/components/Page"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {optOutAPI} from "@indebted/api"
import {ErrorPage} from "@indebted/components/Error"
import {LoadingPage} from "@indebted/components/Loading"
import {TextToHTML} from "@indebted/components/TextToHTML"

import {OptOutFailurePage} from "./OptOutFailurePage"

function OptOutConfirmed({token, channel}) {
	const [Component, setComponent] = useState(<LoadingPage />)

	function Result({I18n}) {
		return (
			<Page>
				<Grid>
					<Typography align="center" variant="h6">
						{I18n.Title}
					</Typography>
				</Grid>
				<Grid>
					<TextToHTML align="center" text={I18n.Subtitle} />
				</Grid>
			</Page>
		)
	}

	useEffect(() => {
		optOutAPI
			.unsubscribe({token, channel})
			.then((result) => {
				setComponent(<Result {...result} />)
			})
			.catch((error) => {
				const errors = {
					400: <OptOutFailurePage I18n={error.i18n} />,
					404: <OptOutFailurePage I18n={error.i18n} />,
					500: <ErrorPage message={error.Message} />,
				}

				setComponent(errors[error.Status] || errors[500])
			})
	}, [token, channel])

	return Component
}

export {OptOutConfirmed}
