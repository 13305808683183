import React from "react"
import {Page} from "@indebted/components/Page"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {Prop, PropLabel, Props, PropValue} from "@indebted/components/Props"
import {TextToHTML} from "@indebted/components/TextToHTML"

function PaymentPlanMandateRevoked({I18n, PaymentPlanInstalmentAmount, PaymentPlanFrequency, PaymentPlanStartDate}) {
	return (
		<Page>
			<Grid>
				<Typography align="center" variant="h6">
					{I18n.MandateRevoked.Title}
				</Typography>
			</Grid>

			<Grid>
				<TextToHTML text={I18n.MandateRevoked.Message} className="clarity-mask" />
			</Grid>

			<Grid>
				<Typography>{I18n.MandateRevoked.DetailsTitle}</Typography>
			</Grid>
			<Props>
				<Prop>
					<PropLabel align="right">{I18n.MandateRevoked.PlanLabel}</PropLabel>
					<PropValue align="left">
						{PaymentPlanInstalmentAmount} {PaymentPlanFrequency}
					</PropValue>
				</Prop>
				<Prop>
					<PropLabel align="right">{I18n.MandateRevoked.StartDateLabel}</PropLabel>
					<PropValue align="left">{PaymentPlanStartDate}</PropValue>
				</Prop>
			</Props>
		</Page>
	)
}
export {PaymentPlanMandateRevoked}
