import React, {useEffect} from "react"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {track} from "@indebted/analytics"

import Success from "./success.svg"

function GrantESIGNConsentSuccess({i18n, customerID}) {
	useEffect(() => {
		track("GrantESIGNConsentSuccessViewed", {
			CustomerID: customerID,
		})
	}, [customerID])

	return (
		<div>
			<Grid
				container
				spacing={7}
				justifyContent="center"
				alignItems="center"
				direction="column"
				style={{padding: "32px 24px"}}
			>
				<Grid>
					<img src={Success} />
				</Grid>
				<Grid>
					<Typography variant="h4" style={{color: "#626392", fontWeight: 700}}>
						{i18n.SuccessHeading}
					</Typography>
				</Grid>
				<Grid style={{padding: "0 14px"}}>
					<Typography align="center">{i18n.SuccessMessage}</Typography>
				</Grid>
			</Grid>
		</div>
	)
}

export {GrantESIGNConsentSuccess}
