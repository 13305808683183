import React from "react"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {ExclamationMark} from "@indebted/assets"

import {Page} from "./Page"

function PaymentConfirmationTimeout({I18n}) {
	return (
		<Page>
			<Grid xs style={{display: "flex", justifyContent: "center"}}>
				<ExclamationMark />
			</Grid>
			<Grid>
				<Typography align="center" variant="h6">
					{I18n.PaymentConfirmationTimeoutMessage.Title}
				</Typography>
			</Grid>

			<Grid>
				<Typography align="center" display="block">
					{I18n.PaymentConfirmationTimeoutMessage.Subtitle}
				</Typography>
			</Grid>
		</Page>
	)
}

export {PaymentConfirmationTimeout}
