import React from "react"
import {Routes, Route, useParams} from "react-router-dom"
import {setRegionFromSecureCode} from "@indebted/api"
import {NotFoundPage} from "@indebted/components/Error"

import {SecureCodeLandingPage} from "./SecureCodeLandingPage"
import {PaymentInFullPage} from "./PaymentInFullPage"
import {PartialPaymentPage} from "./PartialPaymentPage"
import {PartialPaymentStatusPage} from "./PartialPaymentStatusPage"
import {PaymentStatusPage} from "./PaymentInFullStatusPage"
import {PaymentPlanPage} from "./PaymentPlanPage"
import {PaymentPlanStatusWithSecureCodePage} from "./PaymentPlanStatusPage"
import {AffordabilityAssessmentPage} from "./AffordabilityAssessmentPage"

function Pages() {
	const {secureCode} = useParams()
	setRegionFromSecureCode(secureCode)

	return (
		<Routes>
			<Route element={<SecureCodeLandingPage />} path="/" />
			<Route element={<PaymentInFullPage />} path="/payment-in-full/*" />
			<Route element={<PartialPaymentPage />} path="/partial-payment/*" />
			<Route element={<PaymentStatusPage />} path="/payment-in-full-status/:paymentID" />
			<Route element={<PartialPaymentStatusPage />} path="/partial-payment-status/:paymentID" />
			<Route element={<PaymentPlanPage />} path="/payment-plan/*" />
			<Route element={<PaymentPlanStatusWithSecureCodePage />} path="/payment-plan-status/:paymentPlanID" />
			<Route element={<AffordabilityAssessmentPage />} path="/affordability-assessment/*" />
			<Route element={<NotFoundPage />} path="*" />
		</Routes>
	)
}

export {Pages}
