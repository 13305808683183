import React from "react"
import {Page} from "@indebted/components/Page"
import Grid from "@mui/material/Unstable_Grid2"
import {StripeUSBankAccountForm} from "@indebted/stripe"
import {useLocation} from "react-router-dom"

function USDirectDebitForm({i18n, locale, publishableKey, clientSecret, onSubmit}) {
	const location = useLocation()

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" spacing={5} padding={0}>
				<Grid>
					<StripeUSBankAccountForm
						i18n={i18n}
						locale={locale}
						publishableKey={publishableKey}
						clientSecret={clientSecret}
						onSubmit={onSubmit}
						onCollect={(stripe, stripeObj) =>
							stripe.collectBankAccountForPayment(stripeObj).then((result) => {
								return {intent: result.paymentIntent, error: result.error}
							})
						}
						paymentMethodSelectionLink={`PaymentMethodSelection/${location.search}`}
					/>
				</Grid>
			</Grid>
		</Page>
	)
}

export {USDirectDebitForm}
