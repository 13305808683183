import React from "react"
import {Page} from "@indebted/components/Page"
import {Typography, Button} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {track} from "@indebted/analytics"
import {useParams, useSearchParams, useNavigate} from "react-router-dom"
import {format} from "date-fns"
import {CalendarCheckIcon} from "@indebted/assets"

function WeeklyPayDay({i18n, frequency, todayStartDateEnabled}) {
	const navigate = useNavigate()
	const [params] = useSearchParams()
	const {secureCode} = useParams()
	track("PPDayPageViewed", {
		SecureCode: secureCode,
	})

	function PayTodayButton({buttonLabel}) {
		return (
			<Grid>
				<Button
					fullWidth
					variant="outlined"
					onClick={() => {
						track("PPPayTodayButtonClicked", {
							SecureCode: secureCode,
						})
						const date = format(new Date(), "yyyy-MM-dd")
						navigate(`../Amount?${params}&frequency=${frequency.ID}&payday=&startdate=${date}`)
					}}
				>
					{buttonLabel}
				</Button>
			</Grid>
		)
	}

	function WeekdayButton({day}) {
		return (
			<Grid>
				<Button
					fullWidth
					variant="outlined"
					onClick={() => {
						track("PPDayButtonClicked", {
							SecureCode: secureCode,
						})
						navigate(`../Amount?${params}&frequency=${frequency.ID}&payday=${day.ID}`)
					}}
				>
					{day.Display}
				</Button>
			</Grid>
		)
	}

	function PayDayOptions() {
		return (
			<>
				<Grid>
					<Typography variant="h6">{i18n.DateSelection.DateSelectionHeader}</Typography>
				</Grid>
				<Grid>
					<Typography>{i18n.DateSelection.PayDateTitle}</Typography>
				</Grid>

				<Grid container direction="column" alignItems="stretch" spacing={5}>
					{frequency.Options.map((option) => (
						<WeekdayButton key={option.ID} day={option} />
					))}
				</Grid>

				<Grid container direction="column" alignItems="stretch" spacing={5}>
					<Grid>
						<Typography variant="h6">{i18n.DateSelection.CustomStartDateHeader}</Typography>
					</Grid>
					<Grid>
						<Typography>{i18n.DateSelection.CustomStartDateDescription}</Typography>
					</Grid>
				</Grid>

				<Grid container direction="column" alignItems="stretch" spacing={5}>
					<Grid>
						<Button
							fullWidth
							variant="outlined"
							onClick={() => {
								track("PPDayButtonClicked", {
									SecureCode: secureCode,
								})
								navigate(`../CustomStartDate?${params}&frequency=${frequency.ID}`)
							}}
						>
							{frequency.CustomStartDate.Display}
						</Button>
					</Grid>
				</Grid>
			</>
		)
	}

	function TodayStartDate() {
		return (
			<>
				<Grid>
					<Typography variant="h6">{i18n.PayToday.Header}</Typography>
				</Grid>
				<Grid>
					<Typography>{i18n.PayToday.TitleWeekly}</Typography>
				</Grid>

				<PayTodayButton buttonLabel={i18n.PayToday.ButtonLabel} />

				<Grid>
					<Button
						fullWidth
						variant="outlined"
						startIcon={<CalendarCheckIcon />}
						onClick={() => {
							track("PPDayButtonClicked", {
								SecureCode: secureCode,
							})
							navigate(`../CustomStartDate?${params}&frequency=${frequency.ID}`)
						}}
					>
						{i18n.PayToday.CustomStartDateButtonLabel}
					</Button>
				</Grid>
			</>
		)
	}

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={5} padding={0}>
				{todayStartDateEnabled ? <TodayStartDate /> : <PayDayOptions />}
			</Grid>
		</Page>
	)
}

export {WeeklyPayDay}
