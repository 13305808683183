import {Routes, Route, useSearchParams} from "react-router-dom"
import React from "react"
import {setRegionFromAWSRegion} from "@indebted/api"

import {NotificationOfAssignmentAcknowledgement} from "./NotificationOfAssignmentAcknowledgement"

function NotificationOfAssignmentAcknowledgementPage() {
	const [searchParams] = useSearchParams()
	const awsRegion = searchParams.get("region")
	setRegionFromAWSRegion(awsRegion)

	return (
		<Routes>
			<Route element={<NotificationOfAssignmentAcknowledgement />} path="/" />
		</Routes>
	)
}

export {NotificationOfAssignmentAcknowledgementPage}
