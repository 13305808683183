import React from "react"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {Page} from "@indebted/components/Page"
import {TextToHTML} from "@indebted/components/TextToHTML"

function BankruptcyScrubDetected({i18n}) {
	return (
		<Page>
			<Grid>
				<Typography align="center" variant="h6">
					{i18n.Title}
				</Typography>
			</Grid>

			<Grid>
				<TextToHTML align="center" text={i18n.Message} className="clarity-mask" />
			</Grid>
		</Page>
	)
}

export {BankruptcyScrubDetected}
