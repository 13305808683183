import React from "react"
import {Page} from "@indebted/components/Page"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {TextToHTML} from "@indebted/components/TextToHTML"

function OptOutConfirmed({I18n}) {
	return (
		<Page>
			<Grid>
				<Typography align="center" variant="h6">
					{I18n.Title}
				</Typography>
			</Grid>
			<Grid>
				<TextToHTML align="center" text={I18n.Subtitle} />
			</Grid>
		</Page>
	)
}

export {OptOutConfirmed}
