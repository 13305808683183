import {Box, Button, IconButton, Modal, Paper, Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {TextToHTML} from "@indebted/components/TextToHTML"
import React, {useState} from "react"
import styled from "styled-components"
import CloseIcon from "@mui/icons-material/Close"

function CPAConfirmationDialogForDigitalWallet({onCancel, i18n, open, paymentRequest, icon}) {
	const [disabled, setDisabled] = useState(false)
	const [disableBackdropClick, setDisableBackdropClick] = useState(false)
	const [disableEscapeKeyDown, setDisableEscapeKeyDown] = useState(false)

	const submitButtonClicked = (event) => {
		event.preventDefault()

		setDisabled(true)
		setDisableBackdropClick(true)
		setDisableEscapeKeyDown(true)

		paymentRequest.show()
	}

	const cancelButtonClicked = (event) => {
		event.preventDefault()

		setDisabled(false)
		setDisableBackdropClick(false)
		setDisableEscapeKeyDown(false)

		onCancel()
	}

	return (
		<Modal
			open={open}
			onClose={cancelButtonClicked}
			disableEscapeKeyDown={disableEscapeKeyDown}
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
		>
			<StyledPaper>
				<Box
					style={{width: "100%"}}
					disableBackdropClick={disableBackdropClick}
					disableEscapeKeyDown={disableEscapeKeyDown}
				>
					<Grid container direction="column" spacing={3}>
						<Grid
							container
							direction="row"
							spacing={3}
							style={{alignItems: "center", justifyContent: "flex-start"}}
						>
							<Grid xs={9} sm={10} style={{paddingLeft: "12px", paddingTop: "18px"}}>
								<Typography
									variant="subtitle1"
									style={{fontWeight: "bold", lineHeight: 1.3}}
									align="left"
								>
									{i18n.CPAModalTitle}
								</Typography>
							</Grid>
							<Grid xs={3} sm={2} style={{display: "flex", justifyContent: "flex-end"}}>
								<IconButton aria-label="close" onClick={cancelButtonClicked} disabled={disabled}>
									<CloseIcon htmlColor="rgba(50,50,50,0.45)" />
								</IconButton>
							</Grid>
						</Grid>
						<Grid style={{paddingLeft: "6px"}}>
							<TextToHTML text={i18n.CPADigitalWalletModalText} />
							<TextToHTML text={i18n.CPAModalConditionText} color="textSecondary" />
						</Grid>
						<Grid>
							<StyledDigitalWalletButton
								fullWidth
								type="button"
								onClick={submitButtonClicked}
								disabled={disabled}
							>
								<Grid container direction="row" justifyContent="center" alignItems="center">
									<Grid>{i18n.CPADigitalWalletPayButtonLabel}</Grid>
									<StyledDigitalWalletIcon src={icon} />
								</Grid>
							</StyledDigitalWalletButton>
						</Grid>
						<Grid>
							<Button fullWidth variant="outlined" onClick={cancelButtonClicked} disabled={disabled}>
								{i18n.CPAModalSecondaryButtonLabel}
							</Button>
						</Grid>
					</Grid>
				</Box>
			</StyledPaper>
		</Modal>
	)
}

const StyledPaper = styled(Paper)`
	position: relative;
	width: 400px;
	max-height: 95%;
	background-color: ${(props) => props.theme.palette.background.paper};
	box-shadow: ${(props) => props.theme.shadows[5]};
	padding: ${(props) => props.theme.spacing(2, 4, 2)};
	outline: none;
	overflow: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 24px;
	padding-bottom: 20px;

	@media screen and (max-width: 599px) {
		width: 90%;
	}
`

const StyledDigitalWalletButton = styled(Button)`
	background-color: #000;
	color: #fff;

	&:hover {
		background-color: #252525;
	}
`

const StyledDigitalWalletIcon = styled.img`
	margin: 0 0 0 5px;
`

export {CPAConfirmationDialogForDigitalWallet}
