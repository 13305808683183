import React, {useEffect, useState} from "react"
import {customerAPI, setRegionFromRegionNumber} from "@indebted/api"
import {ErrorPage} from "@indebted/components/Error"
import {LoadingPage} from "@indebted/components/Loading"
import {useNavigate, useParams, useSearchParams} from "react-router-dom"

import {GrantESIGNConsent} from "./GrantESIGNConsent"
import {GrantESIGNConsentSuccess} from "./GrantESIGNConsentSuccess"

function GrantESIGNConsentPage() {
	const [Component, setComponent] = useState(<LoadingPage />)
	const params = useParams()
	const [searchParams] = useSearchParams()

	setRegionFromRegionNumber(params.regionPrefix || 0)

	const customerID = searchParams.get("customerID")

	const navigate = useNavigate()

	useEffect(() => {
		const consentGranted = (i18n, customerID) => {
			setComponent(<GrantESIGNConsentSuccess customerID={customerID} i18n={i18n} />)
		}

		customerAPI
			.requestESIGNConsentForm({customerID})
			.then((result) => {
				if (result.ESIGNConsentGranted) {
					setComponent(<GrantESIGNConsentSuccess i18n={result.I18n} />)
					return
				}

				setComponent(
					<GrantESIGNConsent i18n={result.I18n} onSuccess={() => consentGranted(result.I18n, customerID)} />,
				)
			})
			.catch((error) => {
				if (error.Status === 404 && !params.regionPrefix) {
					const url = new URL(window.location.href)
					url.pathname = "/customers/1/grant-esign-consent" // Move to US to check if user is US only [Incident-35]
					navigate(url.href)
				} else {
					setComponent(<ErrorPage message={error.Message} customerID={customerID} />)
				}
			})
	}, [customerID, navigate, params.regionPrefix])

	return Component
}

export {GrantESIGNConsentPage}
