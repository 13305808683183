import {Page} from "@indebted/components/Page"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {InvalidCodeImage} from "@indebted/assets"
import React from "react"
import styled from "styled-components"

function OptOutFailurePage({I18n}) {
	return (
		<Page>
			<Grid>
				<Typography align="center" variant="h6">
					{I18n.Title}
				</Typography>
			</Grid>

			<GridImage>
				<InvalidCodeImage />
			</GridImage>
		</Page>
	)
}

const GridImage = styled(Grid)`
	margin: ${(props) => props.theme.spacing(5)};
`

export {OptOutFailurePage}
