import styled from "styled-components"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"

const Props = styled(Grid).attrs({
	container: true,
	direction: "column",
	width: "100%",
	paddingRight: "0px",
})``

const Prop = styled(Grid).attrs({
	container: true,
	direction: "row",
	width: "100%",
	paddingRight: "0px",
})`
	* {
		width: 50%;
		padding: ${(props) => props.theme.spacing(1)};
	}
`

const PropLabel = styled(Typography)`
	text-align: right;
`

const PropValue = styled(Typography)`
	text-align: left;
`

export {Props, Prop, PropLabel, PropValue}
