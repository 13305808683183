import React from "react"
import {Page} from "@indebted/components/Page"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {Link, useParams} from "react-router-dom"

function PaymentFailed({I18n}) {
	const {secureCode} = useParams()

	return (
		<Page>
			<Grid>
				<Typography align="center" variant="h6">
					{I18n.PaymentFailed.Title}
				</Typography>
			</Grid>

			<Grid>
				<Typography align="center">
					<Link to={`/${secureCode}`}>{I18n.PaymentFailed.RetryLinkText}</Link>
				</Typography>
			</Grid>
		</Page>
	)
}
export {PaymentFailed}
